<!--  -->
<template>
    <div class='Submited'>
        <div class="header">
            <div class="return" @click="backToApproval">
                <img src="@/assets/ioc/angle-left.png" alt="">
                <span>发起审批</span>
            </div>
        </div>
        <div class="mainOn">
            <div style="width: 120px;height: 120px;"><img src="https://cdn.fengwork.com/images/Group_1209.png" alt=""
                    style="width: 100%;height: 100%;"></div>
            <div style="font-weight: 600;font-size: 14px;color: #1b1b1b;margin-top: 20px;margin-bottom: 32px;">提交成功!
            </div>
            <div style="display: flex;">
                <div class="eBtn" @click="handleView">查看详情</div>
                <div class="eBtn" style="background-color: #5669ec;color: #ffffff;margin-left: 20px;"
                    @click="handleRepeat">再次提交</div>
            </div>
        </div>
        <processDetails ref="processDetails" :drawerData="drawerData" @closeDrawer="closeDrawer"></processDetails>
    </div>
</template>

<script>
    import processDetails from "@/components/processDetails.vue";
    import { flowVerifyByDesignId } from "@/api/bpm/flow";
    import { getCountMsg } from '@/api/examines.js'

    export default {
        name: 'Submited',
        components: { processDetails },
        data() {
            return {
                drawerData: {},
                id: ''
            };
        },
        //监听属性 类似于data概念
        computed: {},
        //监控data中的数据变化
        watch: {},
        filters: {},

        methods: {
            backToApproval() {
                this.$router.push({ name: 'Home' });
            },
            async handleView() {
                await getCountMsg(this.id)
                this.$refs.processDetails.drawer = true;

            },
            handleRepeat() {
                const query = { taskType: 1, reStartId: this.id }
                this.$router.push({
                    name: "Create",
                    query
                });
            },
            closeDrawer() {

            },

        },
        // 生命周期 - 创建完成（可以访问当前this实例）
        created() {
            const { id } = this.$route.query;
            this.id = id
            this.drawerData = {
                id,
                taskType: 1
            }

        },
        //生命周期 - 挂载完成（可以访问DOM元素）
        // mounted() {
        //     const { id } = this.$route.params;
        //     this.id = id
        //     console.log(`output->id-mounted`, id,this.$route.params)
        //     this.drawerData = {
        //         id,
        //         taskType: 1
        //     }
        // },
        beforeCreate() { },
        beforeMount() { },
        beforeUpdate() { },
        updated() { },
        beforeDestroy() { },
        destroyed() { },
        //如果页面有keep-alive缓存功能，这个函数会触发
        activated() { },
    }
</script>
<style lang='scss' scoped>
    .header {
        display: flex;
        padding: 16px;
        justify-content: space-between;


        div {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        .return {
            span {
                margin-left: 4px;
                color: var(--, rgba(27, 27, 27, 1));
                font-family: "Noto Sans SC";
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                cursor: pointer;
            }
        }


    }

    .mainOn {
        height: calc(100vh - 250px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .eBtn {
        width: 112px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f3f4f6;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 14px;
        color: #848484;
        cursor: pointer;
    }
</style>